export const SKILLS = [
  "C",
  "C++",
  "Java",
  "Kotlin",
  "Python",
  "HTML / CSS",
  "Javascript",
  "Typescript",
  "Swift",
  "React.js",
  "Next.js",
  "SQL",
  "PostgreSQL",
  "Django",
  "Flask",
  "React Native",
  "Android",
  "Jetpack Compose",
  "iOS",
  "SwiftUI",
  "Flutter",
  "Firebase",
  "Node.js",
  "Express.js",
  "Ruby on Rails",
  "Spring Boot",
  "Git",
  "CI / CD",
  "CLEAN Code",
  "TDD",
  "AWS",
];
